<template>
	<div class="app" >
		<div class="kefu" @click="showQrs ">
			<img src="http://cdn1.zhiyileiju.cn/kefuicon1.png">
			<span>在线客服</span>
		</div>
		<!-- <div class="nav">
			<div class="navitem" @click="gotoresult" v-if="query.openid === undefined" >起名结果</div>
			<div class="navitem" @click="gotoplus" style="position: relative;" v-if="query.openid === undefined">
				高分好名
				<img src="http://cdn1.zhiyileiju.cn/WechatIMG99.png"  style="position: absolute;width:30px">
			</div>
			<div class="navitem active" :style="query.openid === undefined ? '' : 'width:100%' ">人工专家起名</div>
		</div> -->
		<!-- 视频播放 -->
		<!-- <video class="b_video" controls="controls" id="b_video" style="max-width: 100%;margin-top: 0px" poster="http://cdn1.zhiyileiju.cn/WechatIMG242%20%281%29.png">
		<source src="http://cdn1.zhiyileiju.cn/%E8%AF%B8%E8%91%9B%E8%B5%B7%E5%90%8D.mp4" type="video/mp4">
		</video> -->
		<!-- <img width="100%" src="http://cdn1.zhiyileiju.cn/WechatIMG181.jpeg" alt=""> -->
		<div class="center" id="agentTitle" style="height: 35px;line-height: 35px;">
			-花多点心思，选出对宝宝一辈子受用的名字-
		</div>
		<div style="background-color: white;padding: 13px;position: relative;">
			<!-- <imgbox src="http://cdn1.zhiyileiju.cn/1731634701104_.pic_hd.jpg"></imgbox> -->
			<i style="display: inline-block;width: 100%;height: 59vh;background: url(http://cdn1.zhiyileiju.cn/1731634701104_.pic_hd.jpg) no-repeat;background-size: 100% 100%;"></i>
			<p class="img_text_"  style="margin-top:-6vh;color: #fff;font-size: 2.5vh;text-align: center;">长按二维码添加道长助理微信</p>
			<!-- <div class="center" style="margin: 8px;">
				每日起名<span style="color:#B95353">25</span>人，现在下单优先安排起名
			</div> -->
			<!-- <van-button round color="#C51C22" @click="chooseTeacher(1)" class="buyorderbtn" >点击请老师起名</van-button> -->
			<div class="img_box" style="text-align:center;margin-top: 8vw;width:50vw;margin:30px auto">
				<img src="http://cdn1.zhiyileiju.cn/WechatIMG350.png" alt="" style="width: 100%;">
				<p style="font-size: 20px;color:#B95353;">长按此处扫码添加</p>
			</div>
			<h1 class="phone_text" style="text-align:center;font-weight: 400;font-size: 3.5vh;display: flex;justify-content: center;align-items: center;margin: 0;">
				<a style="color:#000;" href="tel://15967571020"><img src="http://cdn1.zhiyileiju.cn/1741634710310_.pic_hd.jpg" alt="" style="height:3.5vh;margin-right: 3vw;"> 电话咨询：<span style="color:#FF0C00;">15967571020</span></a>
			</h1>
			<div class="center tip1">
				你将获得起名专家
			</div>
			<ul style="display: flex;justify-content: space-between;">
				<li style="display: flex;align-items: center; width: 33%;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					1次给8个大名
				</li>
				<li style="display: flex;align-items: center;width: 33%;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					名字满意为止
				</li>
				<li style="display: flex;align-items: center;width: 33%;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					1对1解析名字
				</li>
			</ul>
			<ul style="display: flex;justify-content: center;margin-top: 2vw;">
				<li style="display: flex;align-items: center;width: 33%;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					名字解析报告
				</li>
				<li style="display: flex;align-items: center;width: 33%;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					孩子学业运势
				</li>
			</ul> 
			<div class="center" @click="lookpre(1)" style="margin-top: 10px;">
				>> <span style="color: red;text-decoration: underline;">点击查看起名案例</span> &lt;&lt;
			</div>
		</div>

		<!-- 视频播放 -->
		<video class="b_video" controls="controls" id="b_video" style="max-width: 100%;margin-top: 0px" poster="http://cdn1.zhiyileiju.cn/WechatIMG242%20%281%29.png">
		<source src="http://cdn1.zhiyileiju.cn/%E8%AF%B8%E8%91%9B%E8%B5%B7%E5%90%8D.mp4" type="video/mp4">
		</video>

		
		<!-- <div style="background-color: white;padding: 13px;margin-top: 10px;">
			<imgbox src="http://cdn1.zhiyileiju.cn/WechatIMG156.jpeg"></imgbox>
			<div class="center" style="margin: 8px;">
				每日起名<span style="color:#B95353">15</span>人，现在下单优先安排起名
			</div>
			<van-button round color="#C51C22" @click="chooseTeacher(2)" class="buyorderbtn" >点击请专家起名</van-button>
			<div class="center tip1">
				支付后你将获得高级老师
			</div>
			<ul style="display: flex;justify-content: space-around;flex-wrap: wrap;">
				<li style="display: flex;align-items: center; width: 33%;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					1次给8个大名
				</li>
				<li style="display: flex;align-items: center;width: 33%;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					名字满意为止
				</li>
				<li style="display: flex;align-items: center;width: 33%;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					1对1解析名字
				</li>
				<li style="display: flex;align-items: center;width: 33%;margin-top: 10px;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					名字解析报告
				</li>
				<li style="display: flex;align-items: center;width: 33%;margin-top: 10px;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					孩子学业运
				</li>
			</ul>
			<div class="center"  @click="lookpre(2)"  style="margin-top: 10px;">
				>> <span style="color: red;text-decoration: underline;">点击查看起名案例</span> &lt;&lt;
			</div>
		</div>

		<div style="background-color: white;padding: 13px;margin-top: 10px;">
			<imgbox src="http://cdn1.zhiyileiju.cn/881632798406_.pic_hd.jpg"></imgbox>
			<div class="center" style="margin: 8px;">
				每日起名<span style="color:#B95353">5</span>人，现在下单优先安排起名
			</div>
			<van-button round color="#C51C22" @click="chooseTeacher(3)" class="buyorderbtn" >点击请道长起名</van-button>
			<div class="center tip1">
				支付后你将获得高级老师
			</div>
			<ul style="display: flex;justify-content: space-around;flex-wrap: wrap;">
				<li style="display: flex;align-items: center; width: 33%;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					1次给12个大名
				</li>
				<li style="display: flex;align-items: center;width: 33%;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					名字满意为止
				</li>
				<li style="display: flex;align-items: center;width: 33%;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					1对1解析名字
				</li>
				<li style="display: flex;align-items: center;width: 33%;margin-top: 10px;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					名字解析报告
				</li>
				<li style="display: flex;align-items: center;width: 33%;margin-top: 10px;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					孩子学业运
				</li>
				<li style="display: flex;align-items: center;width: 33%;margin-top: 10px;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					父母财运分析
				</li>
			</ul>
			<div class="center"  @click="lookpre(3)" style="margin-top: 10px;">
				>> <span style="color: red;text-decoration: underline;">点击查看起名案例</span> &lt;&lt;
			</div>
		</div>

		<div style="background-color: white;padding: 13px;margin-top: 10px;">
			<imgbox src="http://cdn1.zhiyileiju.cn/WechatIMG164.jpeg"></imgbox>
			<div class="center" style="margin: 8px;">
				每日起名<span style="color:#B95353">3</span>人，现在下单优先安排起名
			</div>
			<van-button round color="#C51C22" @click="chooseTeacher(4)" class="buyorderbtn" >点击请道长起名</van-button>
			<div class="center tip1">
				支付后你将获得特邀道长
			</div>
			<ul style="display: flex;justify-content: space-around;flex-wrap: wrap;">
				<li style="display: flex;align-items: center; width: 40%;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					1次给15个大名
				</li>
				<li style="display: flex;align-items: center;width: 40%;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					1位老师共同甄选
				</li>
				<li style="display: flex;align-items: center;width: 40%;margin-top: 5px;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					名字满意为止
				</li>
				<li style="display: flex;align-items: center;width: 40%;margin-top: 5px;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					1对1解析名字
				</li>
				<li style="display: flex;align-items: center;width: 40%;margin-top: 5px;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					名字解析报告
				</li>
				<li style="display: flex;align-items: center;width: 40%;margin-top: 5px;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					孩子学业运
				</li>
				<li style="display: flex;align-items: center;width: 40%;margin-top: 5px;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					父母财运分析
				</li>
				<li style="display: flex;align-items: center;width: 40%;margin-top: 5px;">
					<van-icon name="http://cdn1.zhiyileiju.cn/Checkbox%20Checked.png" size="18" />
					孩子未来运势
				</li>
			</ul>
			<div class="center"  @click="lookpre(4)" style="margin-top: 10px;">
				>> <span style="color: red;text-decoration: underline;">点击查看起名案例</span> &lt;&lt;
			</div>
		</div>
		
		<div style="background-color: white;padding: 13px;margin-top: 10px;">
			<imgbox src="http://cdn1.zhiyileiju.cn/WechatIMG159.jpeg"></imgbox>
			<div class="center" style="margin: 8px;">
				每日起名<span style="color:#B95353">1</span>人
			</div>
			<van-button round color="#C51C22" @click="chooseTeacher(5)" class="buyorderbtn" >点击请道长起名</van-button>
		</div> -->
		
		<div class="center" style="margin-top: 20px;">
			<img style="width: 40px;" src="http://cdn1.zhiyileiju.cn/gongshgnhugo.jpeg" >
		</div>
		<div class="center" style="margin:8px auto;font-size: 12px;">工商局认证机构·起名专业可靠</div>
		<div class="center"  style="margin:8px auto;font-size: 12px;">
			起名专家致力为您宝宝取个助运一生的好名
		</div>
		<div class="center" style="background-color: #fff;">
			<imgbox src="http://cdn1.zhiyileiju.cn/WechatIMG160.jpeg"></imgbox>
		</div>
		<div class="center" style="background-color: #fff; margin-top: 10px;">
			<imgbox src="http://cdn1.zhiyileiju.cn/1761634711733_.pic_hd.jpg"></imgbox>
		</div>
		
		<!-- <object data="http://cdn1.zhiyileiju.cn/%E8%B5%B7%E5%90%8D%E6%A1%88%E4%BE%8B0916.pdf" type="application/pdf" width="100%" height="100" internalinstanceid="81" >
		<iframe src="http://cdn1.zhiyileiju.cn/%E8%B5%B7%E5%90%8D%E6%A1%88%E4%BE%8B0916.pdf" width="100%" height="100%"></iframe>	
		</object> -->
	
		<div class="center" style="color:#B95353;margin:10px auto;font-size:18px;font-weight:bold">老师手稿起名展示</div>
		<img src="http://cdn1.zhiyileiju.cn/WechatIMG162.jpeg" style="width:calc(100% - 26px);margin-left:13px;"
			alt="">
		<div class="ratebox">
			<img width="100%" src="http://cdn1.zhiyileiju.cn/1791634712149_.pic_hd.jpg" >
			<img width="100%" src="http://cdn1.zhiyileiju.cn/1781634712149_.pic_hd.jpg" >
			<img width="100%" src="http://cdn1.zhiyileiju.cn/1771634712148_.pic_hd.jpg" >
		</div>

		<div class="orderbox">
			<div class="neworderitp">常见问题</div>
			<van-collapse v-model="activeNames" :accordion="true">
				<van-collapse-item :is-link="false" title="你们是正规起名平台吗？付款起名后你们不给起名怎么办？" name="1">
					好，您的顾虑我们非常理解！您选择找诸葛老师起名绝对放心。<br />
					第一：诸葛起名是网上起名行业的门户网站，是很受欢迎的起名网站，比肩百度等各大搜索引擎搜索排名第一位，是网上起名行业的标杆，大平台大品牌值得您的信赖。<br/> <br/>
					第二：诸葛起名网为公司经营，公司将起名测名作为主业，您从公司名字就可以看出公司对起名的专注和重视。<br/><br/>
					第三：诸葛起名网老师【孙老师】、【翟老师】和【持源道长】从事起名行业有数十年，为数万人提供过起名测名服务。并且人工客服一对一全程跟踪服务，名字不满意免费重起，直至您满意定名为止。<br/>
				</van-collapse-item>
				<van-collapse-item :is-link="false" title="老师起名需要多长时间？有多少个名字可选，不满意怎么办？ " name="2">
					第一：起名是需要老师考虑到诸多方面精心设计，用字分析、音韵音律等各种细节分析，根据分析反复进行筛选从而定名，整套名字方案千字到万字不等，所以一般是1-2天左右给您发送名字方案PDF文档，如果急需的话，可以先安排，这样时间会短一点，一般半天可出结果！<br/><br/>
					第二：根据起名套餐我们老师会给您提供相应的名字数量及起名策划书，您可根据喜好挑选名字。如果您对名字不满意的情况下要及时向我们反馈，我们老师团队会为您将名字方案再次调整，直到您满意定名为止！我们顾客一般一到两天就拿到满意的名字！
				</van-collapse-item>
				<van-collapse-item :is-link="false" title="为什么老师起名的价格那么贵？可否满意后再付款或先付定金？" name="3">
					第一：我们都是请的是【国内易经协会的首席起名专家】孙老师、翟老师和持源道长，亲自分析孩子的国学起名与能量学来起名的，对孩子以后的生活是很有帮助的，起名也要考虑到诸多方面精心设计，适合其自身的，又能带来美好前程的美名，所以起出一个吉祥的好名字是需要我们起名团队长时间精心琢磨的，且动用脑力而得出的知识产品，所以我们起名是需要收费的。<br/><br/>
					第二：“天下没有免费的午餐”，任何无条件的免费服务要么有其目的，要么水平低劣。诸葛起名网也有免费的名字大全，但都是纯粹的名字参考，没有考虑国学五行、音韵音律、古文出处、生肖喜忌，流行趋势，所以单纯名字只能开拓灵感，没有实用意义！<br/><br/>
					第三：因为每天咨询起名的人非常多，如果每个人都先起名，我们也不可能忙得过来，况且我们要保证名字的质量，所以起名是需要先付全款，不支持先付定金，后续起到满意为止。
				</van-collapse-item>
				<van-collapse-item :is-link="false" title="为什么不同老师起名的价格不一样？有什么不同？" name="4">
					起名的价格主要是根据起名的团队不同，套餐所包含的服务内容不同而定价格。因为老师的经验资质不同，所以价格也会有所不同。<br/><br/>
					每个家庭的文化背景不同，宝宝的性格不同以及家长对宝宝的期望不同，家族对名字的要求也不尽一样。所以对命名服务就会有不同的定制需求。【孙老师、翟老师和持源道长】了解每位家长的需要，您可以自由选择适合您的套餐，得到超值的服务回报！<br/><br/>
					【孙老师、翟老师和持源道长】所有的设计套餐服务价格，都是公开透明的，您可以随时在套餐类型里查询到相应的服务价格。我们敢于将价格透明，是因为我们对我们服务品质充满信心！
				</van-collapse-item>
				<van-collapse-item :is-link="false" title="现在网上起名机构那么多，都不知道选哪家好..." name="5">
					这就要看你自己辨别能力，我们也不能说其它起名机构怎么样？诸葛国学起名网是网上起名行业门户网站，用质量和信誉来获得用户的认同，其智能起名每年为千宝宝提供在线起名和测名服务，有着强大的大数据分析用于人工起名的平台，请来的老师也是行业专家【孙老师】、【翟老师】和【持源道长】非一般个人或小公司小作坊所能比的。
				</van-collapse-item>
			</van-collapse>
		</div>
		<!-- <van-button class="yiduiyi"  :class="fixedbtn == true ? 'isFixed' : ''"  block color="#C51C22" @click="gotopay" type="info">请老师一对一起名(名额有限)</van-button> -->
		<van-button class="yiduiyi"  :class="fixedbtn == true ? 'isFixed' : ''"  block color="#C51C22" @click="gotopay" type="info">请老师起名</van-button>
		<!-- 弹窗添加二维码 -->
		<van-popup v-model="showQr">
			<div class="qrbox" v-if="qr_codeL == ''">
				<i class="close" @click="showQr = false"  ></i>
				<div class="navbar center"  >
					<i class="logoicon"></i>
					<span>长按或截图二维码与客服联系</span>
				</div>
				<div class="picbox center">
					<img src="http://cdn1.zhiyileiju.cn/1831634721561_.pic_hd.jpg" alt="" v-if="qr_codeL==''">
					<!-- <img src="http://cdn1.zhiyileiju.cn/WechatIMG2.jpeg" alt="" v-else> -->
					<span >扫描二维码，添加我的企业微信</span>
				</div>
			</div>
			<div class="qrbox" v-else>
				<i class="close" @click="showQr = false"  ></i>
				<!-- <div class="navbar center"  >
					<i class="logoicon"></i>
				</div> -->
				<div class="picbox center" style="background: #fff;padding-top: 20px;">
					<img src="http://cdn1.zhiyileiju.cn/WechatIMG4.jpeg" >
				</div>
			</div>
		</van-popup>

			<!-- 支付弹窗 -->
		<van-popup v-model="showBuybox">
			<div class="buybox">
				<i class="close" @click="showBuybox = false"></i>
				<div class="buyheader center">
					<img class="tecpic" :src="choosedteacher.tv_pic" alt="">
					<div class="titbox">
						<div class="tit">{{choosedteacher.name}}-1对1起名</div>
						<div class="pridesc">原价：<span
								style="text-decoration: line-through;">￥{{choosedteacher.unique_fake_amount}}</span>&nbsp;&nbsp;超值价:<span
								class="emp">￥{{choosedteacher.unique_true_amount}}</span></div>
					</div>
				</div>
				<div class="contentbox center">
					<div class="center">距离特价结束&nbsp;&nbsp;&nbsp;
						<van-count-down millisecond color="#fd0101" :time="time" format="HH:mm:ss:SS" />
					</div>
				</div>

				<div class="paybtnbox">
					<van-button type="primary" color="#04C967"
						style="margin-bottom: 14px;width:calc(100% - 0px);font-size:22px"
						icon="http://cdn1.zhiyileiju.cn/wechaticon111.png" round block @click="getpayurl(2)">微信支付</van-button>
					<van-button type="primary" color="#1B95D4" icon="http://cdn1.zhiyileiju.cn/alipayicon111.png" round
						style="width:calc(100% - 0px);font-size:22px" block v-if="!is_weixn" @click="getpayurl(1)">支付宝
					</van-button>
					<div class="center psytip">付款后会有专业老师联系您一对一跟进服务</div>
					<img class="center" style="width: 100%; margin-top: 15px" src="https://img.nnabc.cn/baozhang.png"
						alt="" />
					<div class="center psytip " style="color:#19AA0f">安全联盟认证请放心使用</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import Comment from "../components/Comment.vue";
	import OrderScroll from "../components/OrderScroll.vue"
	import report from "../utils/stat";
	import {
		Popup,
		Toast,CountDown,Icon
	} from 'vant';

	import axios from "../utils/request";
	import router from "../router";

	export default {
		components: {
			Comment,
			OrderScroll,
			vanPopup: Popup,
			vanCountDown: CountDown,
			vanIcon: Icon
		},
		data() {
			return {
				activeNames: 0,
				showQr: false,
				showBuybox: false,
				teacherInt: false,
				time: 10 * 60 * 1000,
				teacher: [],
				query:{},
				choosedteacher: {},
				qr_codeL:'',//微信二维码图片路径
				fixedbtn:false,
				orderfakedata: [{
						name: "王妈妈",
						time: "12分钟前",
					},
					{
						name: "李妈妈",
						time: "7分钟前",
					},
					{
						name: "孙爸爸",
						time: "3分钟前",
					},
					{
						name: "刘妈妈",
						time: "5分钟前",
					},
					{
						name: "赵阿姨",
						time: "1分钟前",
					},
					{
						name: "周妈妈",
						time: "3分钟前",
					},
					{
						name: "李阿姨",
						time: "3分钟前",
					},
					{
						name: "古妈妈",
						time: "5分钟前",
					},
				],
			}
		},
		destroyed() {
		  this.timer && clearTimeout(this.timer);
		},
		computed: {
			is_weixn() {
				var ua = navigator.userAgent.toLowerCase();
				if (ua.match(/MicroMessenger/i) == "micromessenger") {
					return true;
				} else {
					return false;
				}
			},
		},
		async created() {
			this.teacher = await this.getTeacher();
			this.query = this.$route.query
			this.showToast();
			report("v3_teacher");
			window.addEventListener("scroll", this.handleScroll);
		},
		async mounted() {
			if (this.is_weixn) {
				let wxconfig = await this.getWxConfig();
				wx.config({
					debug: false,
					appId: wxconfig.appId,
					timestamp: wxconfig.timestamp,
					nonceStr: wxconfig.nonceStr,
					signature: wxconfig.signature,
					jsApiList: ['chooseWXPay']
				});

			}
		},
		methods: {
			// chooseTeacher(item) {
			// 	this.choosedteacher = item
			// 	this.showBuybox = true
			// },
			buy(index) {
				this.choosedteacher = this.teacher[index];
				this.showBuybox = true;
			},
			handleScroll() {
				var scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				if (scrollTop > 300) {
					this.fixedbtn = true;
				} else {
					this.fixedbtn = false;
				}
			},
			showToast() {
				let t = this;
				let arr = this.orderfakedata;
				let data = arr[Math.floor(Math.random() * arr.length)];
				let laoshi = ['孙老师','翟老师']
				let ls =  laoshi[Math.floor(Math.random() * laoshi.length)];
				Toast({
					message: `${data.time} ${data.name}已选择${ls}起名`,
					position: "bottom",
					duration: 2000,
				});
				this.timer = setTimeout(function() {
					t.showToast();
				}, 5000);
			},
			async gotoplus(){
				let data = await axios.get("/order/" + this.$route.params.id);
				let orderinfo = data.data.data;
				if(orderinfo.hasplus){
					router.push({
						name:"plusname",
						params:{
							resultid:this.$route.params.id,
							id:orderinfo.plusoid
						}
					}).catch(err => {})
				}else{
					router.push({
						name:"buyplusname",
						params:{
							id:this.$route.params.id
						}
					}).catch(err => {})
				}
			},
			gotopay(){
				document
					.getElementById("agentTitle")
					.scrollIntoView({
						block: "start",
						behavior: "smooth"
					});
				report("v2_teacher_bottom");
			},
			moreintroduce(item) {
				this.teacherInt = true
				this.choosedteacher = item
			},
			async getWxConfig() {
				let url = window.location.href;

				let data = await axios.post("/payment/wechatconf", {
					url
				})
				return data.data.data.data;
			},
			async getTeacher() {
				let data = await axios.get("/teachers");
				return data.data.data;
			},
			lookpre(teacherid){
				router.push({
					name:"testresultpre",
					params:{
						teacherid:teacherid,
						id:this.$route.params.id,
					}
				}).catch(err => {})
				report("v2_teacher_lookpre");
			},
			gotoresult(){
				router.push({
					name:"payresult",
					params:{id: this.$route.params.id}}).catch(err =>{})
			},
			showQrs  () {
				this.qr_codeL = ''
				this.showQr = true;
			},
			chooseTeacher (teachid) {
				
				this.qr_codeL = 'cdn.zhiyileiju.cn/WechatIMG2.jpeg'
				report("v2_teacher_wechat_"+teachid);
				if ( this.query.openid !== undefined ) {
					this.showBuybox = true//    支付弹窗
					for ( let i = 0; i < this.teacher.length; i++ ) {
						if ( this.teacher[i].id == teachid) {
							this.choosedteacher = this.teacher[i];
							this.choosedteacher.ids_ = teachid ;//每个老师的id
						}
					} 
				}else{
					this.showQr = true//弹出二维码
				}
				// console.log('id',teachid)
				// console.log('数据',this.teacher)
				
			},

			async getpayurl(teachid) {
					// this.choosedteacher = item	
				
				let orderinfo
				report("v2_teacher_wechat_"+this.choosedteacher.ids_);
				try {
					let data_ = {
						"order_id": this.$route.params.id,
						// "teacher_id":teachid,
						"teacher_id":this.choosedteacher.ids_,
						"from_order_id": this.$route.params.id,
						paytype:2
					}
					if( this.query.openid !== undefined ) {
						data_.from_order_id = "EmA2glp",
						data_.order_id = 'EmA2glp',
						data_.openid = this.query.openid,
						data_.fromk = this.query.from,
						data_.kf = this.query.kf

					}
					orderinfo = await axios.post("/order/confirm", data_)
				} catch (err) {
					alert("下单失败")
				}
				try {
					if (this.is_weixn) {
						let payinfo = await axios.get(
							`https://api.zhiyileiju.cn/api/v1/payment/wechat/${orderinfo.data.data.id}/wechat`)
						let pay = payinfo.data.data;
						wx.ready(function() {
							wx.chooseWXPay({
								timestamp: pay.timeStamp,
								nonceStr: pay.nonceStr,
								package: pay.package,
								signType: pay.signType,
								paySign: pay.paySign, // 支付签名
								success: function(res) {
									router.push({
										name: 'teachersuccess'
									});
								}
							});
						});


					} else {
						location.href = `https://api.zhiyileiju.cn/api/v1/payment/wechat/${orderinfo.data.data.id}`;
					}
				} catch (err) {
					alert("下单失败")
				}
			}
		}
	}
</script>
<style lang="less" scoped>
	.tic.van-popup {
		background: none;
	}
	.qrbox {
		width: 93vw;
		max-width: 350px;
		border-radius: 5px;
		position: relative;
		padding-bottom: 25px;

		.close {
			display: inline-block;
			width: 40px;
			height: 40px;
			background: url("http://cdn1.zhiyileiju.cn/closeicon.png") no-repeat center;
			background-size: 30px;
			position: absolute;
			top: 0px;
			right: 0;
		}

		.navbar {
			height: 50px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #B95353;
		}

		.logoicon {
			display: inline-block;
			background: url("http://cdn1.zhiyileiju.cn/logoicon111.png") no-repeat center;
			background-size: 35px;
			width: 35px;
			height: 35px;
			margin-right: 8px;
		}

		.picbox {
			background: #EFF3F7;
			width: 87%;
			margin: 0 auto;
			flex-direction: column;
			padding-bottom: 20px;

			img {
				width: 84%;
				margin: 10px auto;
			}

			span {
				font-size: 10px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #929292;
			}
		}
	}
</style>

<style lang="less" scoped>

	.ratebox {
		width: calc(100% - 26px);
		margin: 0 auto;
		background: #fff;
		margin-top: 13px;

		border-radius: 8px;
		padding-top: 15px;
	}

	.orderbox {

		border-radius: 8px;
		width: calc(100% - 26px);
		margin: 0 auto;
		background: #fff;
		margin-top: 13px;
		padding-bottom: 120px;

		.neworderitp {
			text-align: center;
			font-size: 18px;
			font-weight: bold;
			padding: 13px 0;
			color: #B95353;
		}

	}

	.yiduiyi {
		font-size: 18px;
		font-weight: bold;
		height: 56px;
		max-width: 640px;
		animation: scaleDrew 1.5s ease-in-out infinite;
		
	}
	.isFixed{
		position: fixed;
		bottom: 0;
	}
</style>

<style lang="less" scoped>
	.kefu {
		position: fixed;
		top: 100px;
		right: 0;
		width: 30px;
		height: 90px;
		background: #B95353;
		border-radius: 2px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 18px;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 1000;
	}

	.kefu img {
		width: 13px;
		margin-bottom: 4px
	}

	.kefu span {
		display: inline-block;
		font-size: 14px;
		line-height: 1.2em;
		width: 14px;
	}

	.app {
		max-width: 640px;
		margin: 0 auto;
		// border-left: 1px solid #ccc;
		// border-right: 1px solid #ccc;
		width: 100%;
		min-height: 200vh;
		position: relative;
	}

	.nav {
		height: 35px;
		width: 100%;
		display: flex;
		border-bottom: 1px solid #B95353;
		border-top: 1px solid #B95353;
		margin-bottom: 2px;
		.navitem {
			width: 50%;
			height: 100%;
			text-align: center;
			line-height: 35px;
			font-size: 14px;
			font-weight: bold;
			color: #B95353;
			background: #fff;

			&.active {
				background: #B95353;
				color: #fff;
			}
		}
	}


	.jy_box {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		width: calc(100% - 15px);
		margin: 0 auto;
		margin-top: 5px;
		border: 1px solid #D2C595;
		border-radius: 5px;

		.tipicon {
			display: block;
			width: 40px;
			height: 40px;
			background: url("http://cdn1.zhiyileiju.cn/tipicosns.png") no-repeat center;
			background-size: 16px;
			margin-left: 10px;
			margin-top: 5px;
		}

		.tipbox {
			border-radius: 5px;
			background: #FDF9EA;
			font-size: 14px;
			line-height: 24px;
			padding: 10px;
			position: relative;
		}
	}

	.hh1 {
		font-size: 24px;
		font-family: Source Han Serif SC;
		font-weight: bold;
		color: #323232;
		line-height: 20px;
		margin: 5px auto;
	}
	.header {
		height: 36px;
		width: calc(100% - 16px);
		background-color: #B95353;
		color: #fff;
		font-size: 16px;
		font-weight: bold;
		margin: 0 auto;
		margin-top: 13px;
		border-radius: 8px 8px 0 0;
	}


	.buyorderbtn{
		width: 70%;max-width: 270px;display: block;margin: 0 auto;
		animation: scaleDrew 1.5s ease-in-out infinite;	
	}
	.tip1{
		margin: 15px auto;
		font-size: 16px;
		font-weight: 400;
	}

		.buybox {
		width: 93vw;
		max-width: 350px;
		border-radius: 8px;
		position: relative;
		padding-bottom: 25px;

		.close {
			display: inline-block;
			width: 40px;
			height: 40px;
			background: url("http://cdn1.zhiyileiju.cn/closeicon.png") no-repeat center;
			background-size: 30px;
			position: absolute;
			top: 0px;
			right: 0;
		}

		.buyheader {
			border-bottom: 1px solid #BFBFBF;
			padding-bottom: 15px;
		}

		.tecpic {
			width: 75px;
			margin-top: 15px;
			margin-right: 30px;
		}

		.titbox {
			display: flex;
			flex-direction: column;
			height: 90px;


			.tit {
				margin-top: 20px;
				margin-bottom: 15px;
				font-size: 18px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #525252;
			}

			.pridesc {
				font-size: 12px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #525252;

				.emp {
					font-size: 16px;
					color: #B95353;
				}
			}
		}

		.contentbox {
			margin-top: 14px;
			font-size: 14px;
			color: #323232;

			.van-count-down {
				color: #fd0101;
			}
		}

		.paybtnbox {

			width: 90%;
			margin: 0 auto;
			margin-top: 20px;

			.psytip {
				font-size: 12px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #E46868;
				margin: 10px;
			}

		}
	}
</style>
